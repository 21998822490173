import '@/css/style.css';
//import '@/data/new.json';
//import 'babel-polyfill';

var xmlhttp;
 
function loadData(url, cfunc) {
  if (window.XMLHttpRequest) {
    // code for IE7+, Firefox, Chrome, Opera, Safari
    xmlhttp = new XMLHttpRequest();
  } else {
    // code for IE6, IE5
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  xmlhttp.onreadystatechange = cfunc;
  xmlhttp.open("GET", url, true);
  xmlhttp.send();
};
 
function readFile(url) {

  loadData(url, function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      //console.log(xmlhttp.responseText);
      initNews(JSON.parse(xmlhttp.responseText).artical);
      for(let i=0;i<4;i++){
        document.getElementsByClassName("part-6-item")[i].addEventListener("click",function(){
          showNews(parseInt(this.getAttribute("index")),JSON.parse(xmlhttp.responseText).artical);
        })
      }
      //return xmlhttp.responseText;
    } else {
        //alert("数据请求错误！");
    }
  })
};
function showNews(index,data){
  console.log(index,data)
  document.querySelector(".news-wrap").style.display = "flex";
  document.querySelector(".news-title").innerHTML = data[index].title;
  document.querySelector(".news-txt").innerHTML = data[index].content;
};
function initNews(data){
    try {
        for(var i=0;i<4;i++){
            document.getElementsByClassName("part-6-iitem-title")[i].innerHTML = data[i].title;
            document.getElementsByClassName("part-6-iitem-time")[i].innerHTML = data[i].time;
        };
    } catch (error) {
        
    }

}
document.querySelector(".news-close").addEventListener("click",function(){
  document.querySelector(".news-wrap").style.display = "none";
})

function showProduction(){
  let data = [
    {
      "title": "青橙玩家",
      "content": "青橙玩家IP众创空间位于温州市未来东部复合中心的核心空间区域浙南科技城起步区浙南云谷，是国内第一个专注于孵化互联网视音频IP的众创空间，致力于互联网内容标准化生产及IP众创空间运营。"+
        "</br></br>青橙玩家IP众创由温州慷璐互联网文化发展有限公司为运营主体， 截至到2018年11月底，企业估值达到8000万，并已获得2轮天使轮融资。青橙玩家是由温州慷璐互联文化发展有限公司打造的互联网内容生产机构，是淘宝直播、抖音、新浪、今日头条、抖音、全民、微视、YOO视频、火山等平台的MCN机构。"+     
        "</br></br>青橙玩家布局杭州、温州两地。温州基地落户于温州浙南科技城，拥有全IP化演播大厅 、淘宝直播室、实景拍摄区、录音间、化妆室等众多功能性区域。创始团队拥有十多年的传统媒体经验，拥有国内领先的MCN、短视频、直播等领域的专业团队150余人。其中50余人的专业导演编剧团队确保内容的原创度。 核心创始人为传统媒体资深主持人、导演。创始团队拥有十多年的传统媒体经验，深耕MCN、短 视频、直播等领域"+
        "</br></br>青橙玩家2017年8月成立以来，受到各界广泛关注，于2018年6月进行A轮融资，由战略投资方 立昂技术和沃驰科技 （300603）领投，逐渐在国内内容生产MCN机构中崭露头角。"+
        "</br></br>青橙玩家倾力打造的达人和短视频目前已经在抖音、微视、火山、YOO视频、全民等各大短视频平台获得了极大的流量，全平台粉丝达到2000多万，月CPM（曝光量）超1个亿。2019年，公司战略布局内容电商，实现月销量超300万，并以200%速度递增中。"
    },
    {
      "title": "沃儿童",
      "content": ""
    },
    {
      "title": "IPTV酷动健身",
      "content": "酷动健身是一个专为居家健身打造的视频点播专区。把家庭变成健身房，让用户足不出户就可以享受明星教练提供的各类瘦身美体个性化课程，塑造属于自己的健美身材。目前已拥有：碎片健身、增肌塑形、搏击操、健身房器材、太极、每日瑜伽、爵士舞、跆拳道、有氧操课等优质健身内容，视频时长达上万分钟。"
    },
    {
      "title": "何所忆",
      "content": "何所忆致力于为广大旅行爱好者提供高精准、高品质、高效率的住宿预订服务，针对有旅行住宿需求的人群定制的VIP会员制旅行住宿预订平台。"+
      "</br></br>何所忆与热门旅游城市的高端精品民宿/特色度假酒店进行合作，为我们的会员提供足够精品的客房，在为我们的会员带去切身利益的同时，帮助与我方合作的住宿项目消化闲置房源。项目有娱乐工场、丰厚资本、顺融资本等知名风险投资机构和上市公司高管的股东背景，为项目的持续发展打下坚实的基础。"
    },
    {
      "title": "月月生鲜",
      "content": "月月生鲜是一个围绕海鲜、蔬果喜爱者的生鲜电商平台。月月生鲜拥有新鲜蔬果以及各种海鲜品类，旨在打造全国知名的生鲜类平台，让百姓随时随地的享受到安心的生鲜食品。"
    },
    {
      "title": "暴爽塔防",
      "content": "暴爽塔防是一款激燃爆爽的星空战机类塔防小游戏。游戏战机机型丰富，战斗场面酷炫刺激，玩家可通过击败怪物不断积累金币合成更高级战机从而进行更加刺激的战斗。"
    },
    {
      "title": "来电漫画",
      "content": "来电漫画是一个专门为广大漫友打造的在线漫画阅读平台平台主要有热血、少女、侦探推理类、原创等类型的漫画，满足各大漫友的需求，且平台定期对漫画进行更新，不断提供优质的漫画内容给漫友。"
    },
    {
      "title": "少儿学堂",
      "content": "少儿学堂是以少年儿童为对象而开设的专题，拥有覆盖各个年龄段的高清正版儿童视频节目,内容包含成长故事、家庭教育、美妙儿歌三个板块(建议定价25元/月)。优质片库：海量少儿节目资源，涵盖多种类别，大火热门IP：会说话的汤姆猫等资源，更有全年龄段陪伴型教育课程。优质自制课程：中国美院、浙江音乐学院、浙师大等名校讲师走进家庭。"
    },
  ];

  for(let i=0;i<8;i++){
    document.getElementsByClassName("part-7-item")[i].addEventListener("click",function(){
      showNews(parseInt(this.getAttribute("index")),data);
    })
  }
}

showProduction();

//readFile("src/data/new.txt");  //测试
readFile("../data/new.txt"); //正式
